import React from 'react'
import ServiceItem from './ServiceItem'
import Grid from '@material-ui/core/Grid';
import { graphql, useStaticQuery } from "gatsby"
import { Container, Box } from '@material-ui/core';
import HeaderWithDividers from '../../HeaderWithDividers';

export const query = graphql`
  query {
    allMdx(filter: {frontmatter: {collection: {eq: "service"}}}) {
      nodes {
        frontmatter {
          order
          title
          image
          items
        }
      }  
    }
  }
`

export default () => {
  const data = useStaticQuery(query)
  const services = data.allMdx.nodes

  return (
    <Box id="services" bgcolor="background.paper" py={6}>
      <Container data-sal="fade" data-sal-delay="800" data-sal-duration="1000" >
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <HeaderWithDividers mb={4}>
              Our services
            </HeaderWithDividers>
          </Grid>

          {services.sort((a, b) => a.frontmatter.order - b.frontmatter.order).map(({ frontmatter: { title, image, items } }) => <Grid
            key={title}
            item
            xs={12}
            sm={4}
            >
            <ServiceItem title={title} items={items} image={image} />
          </Grid>)}
        </Grid>
      </Container>
    </Box>
  )
}
