import React from 'react'
import CompanyButton from '../CompanyButton'
import { Grid, Container, Typography, Box, makeStyles } from '@material-ui/core';
import { Link } from "@reach/router"
import image from '../../../static/template-bg.png'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
  topSpacer: {
    paddingTop: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6)
    }
  }
}))


export default () => {
  const classes = useStyles()

  return (
    <Box component={Container} pb={10} className={cx(classes.topSpacer, classes.bgImage)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h2" component="h1" data-sal="fade" data-sal-delay="200" data-sal-duration="1000" >
            Design & software house
        </Typography>

          <Box my={3} data-sal="fade" data-sal-delay="400" data-sal-duration="1000" >
            <Typography color="textSecondary" variant="body1">
              Are you looking for a professional team to build your new app or website? <br />
            Want our developers to be a part of your team? <br />
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} container alignItems="center">
          <Box component={Typography} variant="body1" data-sal="fade" data-sal-delay="400" data-sal-duration="1000" >
            Send us your inquiry so we can provide you with <Box display="inline" component="strong" fontWeight="fontWeightBold">the best solution</Box>.
        </Box>
        </Grid>

        <Grid item xs={12} md={6} container alignItems="center" data-sal="fade" data-sal-delay="400" data-sal-duration="1000">
          <CompanyButton component={Link} to="/contact">Send Inquiry</CompanyButton>
        </Grid>
      </Grid>
    </Box>
  )
}
