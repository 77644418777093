import React from 'react'
import SendIcon from "../../../../static/icons/send.svg"
import CompanyButton from '../../CompanyButton'
import image from "../../../../static/bg-about-us.png"
import { Box, Container, Grid, withStyles, Typography, Hidden } from '@material-ui/core';
import { Link } from '@reach/router'

const styles = theme => ({
  bgImage: {
    position: 'relative',

    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: `url(${image}) no-repeat`,
      backgroundPositionY: 'center',
      backgroundPositionX: '2em',
      opacity: .5
    }
  }
})

const SectionContactUs = ({ classes }) => {

  return (
    <Box id="contact" data-sal="fade" data-sal-delay="200" data-sal-duration="1000" component={Container} py={6} className={classes.bgImage}>
      <Box py={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={6}>
            <Box display="flex" justifyContent="space-between">
              <Typography component="h2" variant="h2" color="textSecondary">
                Contact us
              </Typography>

              <Hidden smUp>
                <img src={SendIcon} alt="" />
              </Hidden>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} lg={6}>
            <Hidden xsDown>
              <Box mb={2}>
                <img src={SendIcon} alt="" />
              </Box>
            </Hidden>

            <Typography variant="h2">
              Send inquiry to get an estimate
            </Typography>

            <Box my={2}>
              <Typography color="textSecondary" variant="body1" gutterBottom>
                Let us know your company and your needs so we can help you to solve the problem you are facing. Be it MVP, complex web app or just a simple website. We are happy to help you achieve your goals.
                </Typography>

              <Box mt={4}>
                <Typography color="textPrimary" variant="body1">
                  Hire us and <Box display="inline" component="strong" fontWeight="fontWeightBold">get your project fast</Box>.
                </Typography>
              </Box>
            </Box>
            <CompanyButton component={Link} to="/contact">Send Inquiry</CompanyButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default withStyles(styles)(SectionContactUs);