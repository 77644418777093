import React from 'react'
import { withStyles, Box, Typography } from '@material-ui/core'

const ServiceItem = ({ classes, title, items = [], image }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <span>
      <img src={image} alt="" />
    </span>
    <Typography variant="h3" className={classes.heading}>{title}</Typography>

    <Box component="ul" flexDirection="column" className={classes.list}>
      {items.map(x => <Typography component="li" key={x} color="textSecondary" className={classes.listItem}>
        {x}
      </Typography>)}
    </Box>
  </Box>
)

const styles = theme => ({
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    margin: '0.2em 0 0'
  },
  list: {
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    textAlign: 'center'
  }
})

export default withStyles(styles)(ServiceItem)