import React from 'react';
import { Helmet } from 'react-helmet';
import SectionTop from '../components/sections/SectionTop';
import SectionAboutAs from '../components/sections/SectionAboutUs';
import SectionProjects from '../components/sections/SectionProjects';
import SectionFaq from '../components/sections/SectionFAQ';
import SectionClients from '../components/sections/SectionClients';
import SectionContactUs from '../components/sections/SectionContactUs';
import SectionServices from '../components/sections/SectionServices';
import Layout from 'src/components/Layout';

export default () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Promate - design & software house</title>
      </Helmet>

      <SectionTop />

      <SectionServices />

      <SectionAboutAs />

      <SectionProjects />

      <SectionFaq />

      <SectionClients />

      <SectionContactUs />
    </Layout>
  );
}
