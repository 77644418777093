import React from 'react'
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { withStyles, Container, Box } from '@material-ui/core';
import { graphql, useStaticQuery } from "gatsby"

const styles = theme => ({
  panel: {
    background: 'transparent',
    boxhadow: 'none',
    padding: 0,
    marginTop: theme.spacing(3),

    '&:before': {
      display: 'none'
    },
  },
  expanded: {
    '&.MuiExpansionPanelSummary-content.Mui-expanded': {
      marginTop: 0,
    }    
  },
  panelHeading: {
    background: 'transparent',
    boxShadow: 'none',
    padding: 0
  },
  heading: {
    cursor: 'pointer'
  },
  details: {
    padding: '8px 0'
  },
})

export const query = graphql`
  query {
    allMdx(filter: {frontmatter: {collection: {eq: "question"}}}) {
      nodes {
        frontmatter {
          id
          answer
          question
        }
      }  
    }
  }
`

function SimpleExpansionPanel({ classes }) {
  const [panel, setPanel] = React.useState(null)
  const data = useStaticQuery(query)

  const questions = data.allMdx.nodes
  const handleChange = nextPanel => _ => setPanel(nextPanel === panel ? null : nextPanel)

  return (
    <Box id="faq" py={6}>
      <Container data-sal="fade" data-sal-delay="200" data-sal-duration="1000" >
        <Grid container justify="center">
          <Grid item xs={12} sm={6}>

            <Typography variant="h2">
              FAQ
            </Typography>

            {questions.map(({ frontmatter: { id, question, answer } }) => <ExpansionPanel
              key={id}
              classes={{ root: classes.panel }}
              elevation={0}
              expanded={panel === id}
              onChange={handleChange(id)}
            >
              <ExpansionPanelSummary classes={{ root: classes.panelHeading, expanded: classes.expanded }}>
                <Typography variant="h3" color={panel === id ? "primary" : undefined} className={classes.heading}>
                  {question}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: classes.details }}>
                <Typography>
                  {answer}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>)}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default withStyles(styles)(SimpleExpansionPanel);

