import React from 'react'
import { Box, Typography, withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    listStyleType: 'none',
    cursor: 'default'
  }
})

const ProjectItem = ({ classes, ...props }) => (
  <Box>
    <Box component={Typography} variant="h3" color="background.default">
      {props.title}
    </Box>

    <Box component="ul" p={0} className={classes.root}>
      {props.spec.map(item => <Typography
        key={item}
        component="li"
        variant="body1"
      >
        <Box color="#dcdcdc">{item}</Box>        
      </Typography>
      )}
    </Box>
  </Box>
)

export default withStyles(styles)(ProjectItem)