import React from 'react'
import image from "../../../static/bg-about-us.png"
import Grid from '@material-ui/core/Grid';
import { Box, Container, withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
  bgImage: {
    position: 'relative',

    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: `url(${image}) no-repeat`,
      backgroundPositionY: '2em',
      backgroundPositionX: '2em',
      opacity: .5
    }

  }
})


function SectionAboutUs({ classes, ...props }) {
  return (
    <Box id="about">
      <Box component={Container} className={classes.bgImage} py={8}>
        <Grid container spacing={2} data-sal="fade" data-sal-delay="200" data-sal-duration="1000" >
          <Grid item xs={12} sm={4} lg={6}>
            <Typography component="h2" variant="h2" color="textSecondary">
              About us
          </Typography>
          </Grid>

          <Grid item xs={12} sm={8} lg={6}>

            <Typography variant="h2">
              We build web and mobile apps
            </Typography>

            <Box my={2}>
              <Typography color="textSecondary" variant="body1" gutterBottom>
                We are a very skilled team of designers and developers with great experience in building apps, webistes for small and big companies.
                </Typography>

              <Typography color="textSecondary" variant="body1">
                We are focused on delivering dedicated software based on our clients needs but we are also happy to work with open source third party solutions to help you build your new MVP with smalles effort needed to do so.
                </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default withStyles(styles)(SectionAboutUs);