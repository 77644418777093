import React from 'react'
import ProjectItem from './ProjectItem'
import { Box, Grid, Container } from '@material-ui/core';
import HeaderWithDividers from '../../HeaderWithDividers';
import { graphql, useStaticQuery } from "gatsby"

export const query = graphql`
  query {
    allMdx(filter: {frontmatter: {collection: {eq: "projects"}}}) {
      nodes {
        frontmatter {
          order
          title
          items
        }
      }  
    }
  }
`

export default () => {
  const data = useStaticQuery(query)
  const items = data.allMdx.nodes
  
  return <Box id="projects" bgcolor="primary.main" py={6}>
    <Container data-sal="fade" data-sal-delay="200" data-sal-duration="1000" >
      <Grid container justify="center">
        <Grid item xs={12}>
          <HeaderWithDividers mb={4}>
            <Box color="#dcdcdc" whiteSpace="nowrap">Latest projects</Box>
          </HeaderWithDividers>
        </Grid>

        <Grid container spacing={2}>
          {items.sort((a, b) => a.frontmatter.order - b.frontmatter.order).map(x => <Grid key={x.frontmatter.title} item xs={6} md={3}>
            <ProjectItem title={x.frontmatter.title} spec={(x.frontmatter.items || [])} />
          </Grid>)}
        </Grid>
      </Grid>
    </Container>
  </Box>
}
