import React from 'react'
import { Grid, Container, Box } from '@material-ui/core';
import HeaderWithDividers from '../../HeaderWithDividers';
import { graphql, useStaticQuery } from "gatsby"

export const query = graphql`
  query {
    allMdx(filter: {frontmatter: {collection: {eq: "clients"}}}) {
      nodes {
        frontmatter {
          order
          title
          image
        }
      }  
    }
  }
`

export default () => {
  const data = useStaticQuery(query)
  const items = data.allMdx.nodes

  return (
    <Box id="clients" py={6} bgcolor="background.paper">
      <Container data-sal="fade" data-sal-delay="200" data-sal-duration="1000" >
        <Grid container justify="center">
          <Grid item xs={12}>
            <HeaderWithDividers mb={4} color="divider">
              We worked with
            </HeaderWithDividers>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center">
              {items.sort((a, b) => a.frontmatter.order - b.frontmatter.order).map((item, i) => <Grid
              key={item.frontmatter.image}
              item
              xs={6}
              md="auto"
              component={Box}
              display="flex"
              alignItems="center"
              justifyContent="center">
                <img src={item.frontmatter.image} title={item.frontmatter.title} alt="" style={{ maxWidth: '100%' }} />
              </Grid>)}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
